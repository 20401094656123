const config = {
  apiKey: "AIzaSyDguOm8y9U4IxwGDJTUg-vZkW5jJWMtCvw",
  authDomain: "weekup-app.firebaseapp.com",
  projectId: "weekup-app",
  storageBucket: "weekup-app.appspot.com",
  messagingSenderId: "417031289183",
  appId: "1:417031289183:web:9d81998b035e4a8d011eea",
  measurementId: "G-ZDWCCKNGQB",
  databaseURL: "https://weekup-app-default-rtdb.firebaseio.com",
};

class Firebase {
  constructor(app) {
    app.initializeApp(config);

    /* Helper */

    this.serverValue = app.database.ServerValue;
    this.emailAuthProvider = app.auth.EmailAuthProvider;

    /* Firebase APIs */

    this.auth = app.auth();
    this.db = app.database();
    // console.log(app);
    this.storage = app.storage();
        // console.log(this.storage.ref());
    /* Social Sign In Method Provider */

    // this.googleProvider = new app.auth.GoogleAuthProvider();
    // this.facebookProvider = new app.auth.FacebookAuthProvider();
    // this.twitterProvider = new app.auth.TwitterAuthProvider();
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  // doSignInWithGoogle = () =>
  //   this.auth.signInWithPopup(this.googleProvider);
  //
  // doSignInWithFacebook = () =>
  //   this.auth.signInWithPopup(this.facebookProvider);
  //
  // doSignInWithTwitter = () =>
  //   this.auth.signInWithPopup(this.twitterProvider);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
      url: "http://localhost:8000",
    });

  doPasswordUpdate = password =>
    this.auth.currentUser.updatePassword(password);

  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.user(authUser.uid)
          .once('value')
          .then(snapshot => {
            const dbUser = snapshot.val();

            // default empty roles
            if (!dbUser.roles) {
              dbUser.roles = {};
            }

            // merge auth and db user
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              emailVerified: authUser.emailVerified,
              providerData: authUser.providerData,
              ...dbUser,
            };
// console.log(authUser);
            next(authUser);
          });
      } else {
        this.user("test")
          .once('value')
          .then(snapshot => {
            const dbUser = snapshot.val();
// console.log(dbUser);
            // default empty roles
            // if (!dbUser.roles) {
            //   dbUser.roles = {};
            // }

let providerData={
  displayName: null,
email: "weekup44@gmail.com",
phoneNumber: null,
photoURL: null,
providerId: "password",
uid: "weekup44@gmail.com",
}
            // merge auth and db user
            authUser = {
              uid: "test",
              email: "test",
              emailVerified: true,
              providerData: providerData,
              ...dbUser,
            };
// console.log(authUser);
            next(authUser);
          });
      }
    });

  // *** User API ***

  user = uid => this.db.ref(`users/${uid}`);

  users = () => this.db.ref('users');

  // *** Message API ***

  // message = uid => this.db.ref(`messages/${uid}`);
  //
  // messages = () => this.db.ref('messages');

  // digital = uid => this.db.ref(`digital-team/${uid}`);
  //
  // digitals = () => this.db.ref('digital-team');

  // player_3 = uid => this.db.ref(`players3_2021/${uid}`);
  //
  //   players_3 = () => this.db.ref('players3_2021');
  //
  //   player_2 = uid => this.db.ref(`players2_2021/${uid}`);
  //
  //     players_2 = () => this.db.ref('players2_2021');
  //
  //     player_1 = uid => this.db.ref(`players1_2021/${uid}`);
  //
  //       players_1 = () => this.db.ref('players1_2021');
  //
  // storage1 = () => this.storage;
  //
  // game = uid => this.db.ref(`games/${uid}`);
  //
  // games = () => this.db.ref('games');
  //
  // staff = uid => this.db.ref(`staff/${uid}`);
  //
  // staffs = () => this.db.ref('staff');
}

let firebase;

function getFirebase(app, auth, database, storage) {
  if (!firebase) {
    firebase = new Firebase(app, auth, database, storage);
  }
// console.log(firebase);
  return firebase;
}

export default getFirebase;
