import React, { Fragment } from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../scss/style.scss';
import logo from '../images/logo.png';
// import 'react-vertical-timeline-component/style.min.css';
// import * as ROUTES from '../src/constants/routes';

// <div className="ob-top-align">
// <img className="ob-top-info" alt="sakai" src={toboriTop} />
// </div>



const CommercialTransactions = () => (
  <Fragment>
  <title>特定商取引法に基づく詳細|マーキーズデザインスタジオ</title>

  <div className="transaction-main">

  <div className="transaction-logo">
  <Link to="/">
  <img alt="logo" src={logo} />
  </Link>
  </div>

  <h2 className="content-title-white">特定商取引法に基づくビジネス詳細</h2>

  <table>

<tr>
<th>法人名</th>
<td>マーキーズデザインスタジオ</td>
</tr>
<tr>
<th>所在地</th>
<td>〒164-0013 東京都中野区弥生町2-20-16</td>
</tr>
<tr>
<th>電話番号</th>
<td>090-7194-2552</td>
</tr>
<tr>
<th>メールアドレス</th>
<td>weekup44@gmail.com</td>
</tr>
<tr>
<th>取締役</th>
<td>真木達</td>
</tr>
<tr>
<th>追加手数料</th>
<td>箱の配送料および処理手数料が別途かかることがあります。</td>
</tr>
<tr>
<th>交換および返品</th>
<td>未開封の商品は同額以下の商品と交換いたしますが、開封後の商品は商品到着後 10 日以内に返品された場合、一部返金いたします。</td>
</tr>
<tr>
<th>配達時間</th>
<td>注文は 3 ～ 5 営業日以内に処理され、商品は 14 日以内に到着します。</td>
</tr>
<tr>
<th>受け付け可能な決済手段</th>
<td>クレジットカードまたは国内の銀行振込</td>
</tr>
<tr>
<th>決済期間</th>
<td>クレジットカード決済の場合はただちに処理されますが、国内の銀行振込の場合は注文から 3 日以内にお振り込みいただく必要があります。</td>
</tr>
<tr>
<th>値</th>
<td>平均価格¥2,000</td>
</tr>

</table>

</div>

<div className="copyright">
Copyright ©　マーキーズデザインスタジオ, All Rights Reserved.
</div>

</Fragment>
);

export default () => (
  <Layout>
  <CommercialTransactions />
  </Layout>
);
